


// Example starter JavaScript for disabling form submissions if there are invalid fields
(function () {
    'use strict'

    // user screen 
const proceedButtons = document.querySelectorAll('.btn-proceed');
const backToCategory = document.querySelector('.btn-back-category');
const scenarioDiv = document.getElementById("scenario");
const categoryDiv = document.getElementById("category");

proceedButtons.forEach(button => {
  button.addEventListener("click", function () {
    // Show the #scenario div when any btn-proceed is clicked
    scenarioDiv.style.display = "block";
    categoryDiv.style.display ="none";
  });
});

if(backToCategory){
    backToCategory.addEventListener("click", function (){
        categoryDiv.style.display ="block";
        scenarioDiv.style.display="none";
    } )
}

// user screen end
// add data source for changing forms

// Get references to the radio buttons and input divs
const radioManual = document.getElementById("inlineRadio1");
const radioAPI = document.getElementById("inlineRadio2");
const radioWebScraping = document.getElementById("inlineRadio3")
const fileUpload = document.getElementById("inlineRadio4")
const manualInputDiv = document.getElementById("manualInput");
const apiInputDiv = document.getElementById("apiInput");
const webScrapingDiv = document.getElementById("webScraping")
const fileUploadDiv = document.getElementById("fileUpload")
// Add event listeners to the radio buttons

radioManual?.addEventListener("change", function () {
    if (this.checked) {
        manualInputDiv.style.display = "block";
        apiInputDiv.style.display = "none";
         webScrapingDiv.style.display = "none";
        fileUploadDiv.style.display ="none"
    }
});

radioAPI?.addEventListener("change", function () {
    if (this.checked) {
        apiInputDiv.style.display = "block";
        manualInputDiv.style.display = "none";
         webScrapingDiv.style.display = "none";
        fileUploadDiv.style.display ="none"
    }
});
radioWebScraping?.addEventListener("change", function () {
    if (this.checked) {
        manualInputDiv.style.display = "none";
        apiInputDiv.style.display = "none";
        webScrapingDiv.style.display = "block";
        fileUploadDiv.style.display ="none"

    }
});
fileUpload?.addEventListener("change", function () {
    if (this.checked) {
        fileUploadDiv.style.display ="block"
        webScrapingDiv.style.display = "none";
        manualInputDiv.style.display = "none";
        apiInputDiv.style.display = "none";
    }
});

// add data source for changing forms end



//  script pop up 

let frameContainer = document.getElementById("account-setting");
let adminProfilePopUp = document.querySelector(".admin-profile-pop-up");
if (frameContainer && adminProfilePopUp) {
    frameContainer?.addEventListener("click", function (e) {
        // Toggle the pop-up
        adminProfilePopUp.classList.toggle("show");


        // Prevent the click event from propagating to the document click listener
        e.stopPropagation();
    });

    // Add a click event listener to the document to hide the pop-up when clicking outside of it
    document.addEventListener("click", function (e) {
        if (!adminProfilePopUp.contains(e.target) && !frameContainer.contains(e.target)) {
            adminProfilePopUp.classList.remove("show");
          

        }
    });
}

// Pop up for Action
// Find all elements with the class "allAction-icon-container"

  document.addEventListener('DOMContentLoaded', function () {
    const allActionIconContainers = document.querySelectorAll('.allAction-icon-container');

    allActionIconContainers.forEach((container) => {
      const icon = container.querySelector('.bi');
      const popUp = container.nextElementSibling; // Assuming the pop-up is the next sibling

      // Add a click event listener to the icon
      icon?.addEventListener('click', (e) => {
        e.stopPropagation(); // Prevent the click event from propagating to the document
        // Calculate the mouse cursor position
        // const mouseX = e.clientX;
        const mouseY = e.clientY;

        // Position the pop-up below the cursor
        // popUp.style.left = `${mouseX}px`;
        // popUp.style.top = `${mouseY}px`;

        // Display the pop-up
        popUp.style.display = 'block';
      });

      // Add a click event listener to close the pop-up when clicking outside
      document.addEventListener('click', (e) => {
        if (popUp.style.display === 'block' && !container.contains(e.target)) {
          popUp.style.display = 'none';
        }
      });
    });
  });


// Pop up for Action end

document?.getElementById('togglePassword')?.addEventListener('click', function() {

    const passwordInput = document.getElementById('passwordInput');
    const icon = document.querySelector('#togglePassword i');

    if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        icon.classList.remove('bi-eye-fill');
        icon.classList.add('bi-eye-slash');
    } else {
        passwordInput.type = 'password';
        icon.classList.remove('bi-eye-slash');
        icon.classList.add('bi-eye-fill');
    }
});
// Confirm password
document?.getElementById('toggleCPassword1')?.addEventListener('click', function() {
    const passwordInput = document.getElementById('CpasswordInput1');
    const icon = document.querySelector('#toggleCPassword1 i'); 

    if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        icon.classList.remove('bi-eye-fill');
        icon.classList.add('bi-eye-slash');
    } else {
        passwordInput.type = 'password';
        icon.classList.remove('bi-eye-slash');
        icon.classList.add('bi-eye-fill');
    }
});

// New password password
document?.getElementById('toggleNewPassword')?.addEventListener('click', function() {
    const passwordInput = document.getElementById('NewpasswordInput');
    const icon = document.querySelector('#toggleNewPassword i'); 

    if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        icon.classList.remove('bi-eye-fill');
        icon.classList.add('bi-eye-slash');
    } else {
        passwordInput.type = 'password';
        icon.classList.remove('bi-eye-slash');
        icon.classList.add('bi-eye-fill');
    }
});


// modal
const myModal = document.getElementById('myModal')
const myInput = document.getElementById('myInput')

myModal?.addEventListener('shown.bs.modal', () => {
  myInput.focus()
})

//nav-item active style js
const navLinks = document.querySelectorAll('.nav-link');
const activeLine = document.querySelector('.active-line');

navLinks?.forEach((link) => {
    link.addEventListener('click', (e) => {
        e.preventDefault();
        // Remove the "active" class from all nav-links
        navLinks.forEach((navLink) => navLink.classList.remove('active'));
        // Add the "active" class to the clicked nav-link
        link.classList.add('active');
        // Update the width of the active line to match the selected nav-link's width
        activeLine.style.width = `${link.clientWidth}px`;
        // Position the active line below the selected nav-link
        activeLine.style.left = `${link.offsetLeft}px`;
    });
});
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')
  
    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }
  
          form.classList.add('was-validated')
        }, false)
      })

      //ADD SOURCES
    //   $("#expand-icon").click(function () {
    //     // Get the content from the original textarea
    //     var content = $("#original-textarea").val();

    //     // Set the content in the modal's textarea
    //     $("#expanded-textarea").val(content);

    //     // Show the modal
    //     $("#expandModal").modal("show");
    //     });
    //     //ADD SOURCES
  })()





